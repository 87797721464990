
import { defineComponent, reactive } from "vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import RadioButton from "primevue/radiobutton";

export default defineComponent({
  name: "TypeYesNoButton",
  components: {
    RadioButton,
  },
  props: {
    isRequired: Boolean,
    isSubmitted: Boolean,
    answerKey: String,
  },
  data() {
    return {
      catalogData: [
        {
          label: "Sí",
          value: true,
        },
        {
          label: "No",
          value: false,
        },
      ],
    };
  },
  setup(props) {
    const f = reactive({
      name: undefined,
    });

    var rules;
    rules = props.isRequired
      ? (rules = {
          name: { required },
        })
      : (rules = {
          name: {},
        });

    const v$ = useVuelidate(rules, f);
    return { f, v$ };
  },
  mounted() {
    var step = JSON.parse(sessionStorage.getItem("steps") || "{}");
    if (
      typeof step === "object" &&
      this.answerKey &&
      Object.prototype.hasOwnProperty.call(step, this.answerKey)
    )
      this.v$.name.$model = step[this.answerKey];
    this.setValues();
  },
  methods: {
    setValues() {
      this.$emit("getValue", {
        value: this.v$.name.$model,
        valid: !(this.v$.name.$model == undefined && this.isRequired),
        answerKey: this.answerKey,
      });
    },
  },
});

export function find() {
  return {
    id: 0,
    name: "Gondolin",
    code: "code",
    questions: [
      {
        id: 0,
        order: 1,
        maxAnswers: 1,
        required: true,
        hasFilter: false,
        name: "Datos de contacto",
        details: `Ingresa tu número celular a ${process.env.VUE_APP_PHONE_LENGTH} dígitos, tu correo electrónico y haz clic en Siguiente`,
        answersKeys: [
          {
            id: 0,
            name: "Teléfono celular",
            key: "phone",
            order: 1,
            type: "string",
            component: "phone",
            required: true,
          }, {
            id: 1,
            name: "Correo electrónico",
            key: "email",
            order: 2,
            type: "string",
            component: "email",
            required: true,
          },
        ],
      },
      {
        id: 2,
        order: 1,
        maxAnswers: 1,
        required: true,
        hasFilter: false,
        name: "Para continuar acepta lo siguiente:",
        details: "",
        answersKeys: [
          {
            id: 2,
            key: "termsAndConditions",
            name: "Acepto los términos y condiciones del servicio",
            order: 0,
            type: "boolean",
            component: "switch",
            required: true,
            catalog: "",
          },{
            id: 3,
            name: "Acepto el aviso de privacidad",
            key: "privacyNotice",
            order: 0,
            type: "boolean",
            component: "switch",
            required: true,
            catalog: "",
          },
        ],
      },
      {
        id: 4,
        order: 2,
        maxAnswers: 1,
        required: true,
        hasFilter: false,
        name: "¿Cuál es tu ingreso mensual?",
        details: "",
        answersKeys: [
          {
            id: 4,
            key: "incomeId",
            order: 0,
            type: "string",
            component: "radiobutton",
            required: true,
            catalog: "income",
          },
        ]
      },
      {
        id: 5,
        order: 2,
        maxAnswers: 1,
        required: true,
        hasFilter: false,
        name: "¿Puedes comprobar tus ingresos?",
        details: "",
        answersKeys: [
          {
            id: 5,
            key: "checkIncome",
            order: 0,
            type: 'boolean',
            component: "yesnobutton",
            required: true,
            catalog: "",
          },
        ],
      },
    ],
  };
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"text-align":"center"},
  class: "my-5"
}
const _hoisted_2 = {
  key: 1,
  class: "radio-group text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!
  const _component_RadioButton = _resolveComponent("RadioButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_spinner, {
            text: "",
            color: _ctx.spinnerColor,
            textColor: _ctx.spinnerTextColor
          }, null, 8, ["color", "textColor"])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.catalogData, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "input-container",
              key: item
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["radio", { 'radio-select': _ctx.v$.name.$model == item.id }])
              }, [
                _createVNode(_component_RadioButton, {
                  class: "radio-button",
                  onChange: _ctx.setValues,
                  value: item.id,
                  modelValue: _ctx.v$.name.$model,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.v$.name.$model) = $event))
                }, null, 8, ["onChange", "value", "modelValue"]),
                _createElementVNode("label", {
                  class: _normalizeClass(["radio-label", [
            _ctx.isRequired && !_ctx.v$.name.$model && _ctx.isSubmitted
              ? 'text-red shake'
              : _ctx.v$.name.$model != item.id
              ? 'text-blue'
              : '',
          ]])
                }, _toDisplayString(item.name), 3)
              ], 2)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}